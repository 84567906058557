"use strict";

import loadScript from '@elements/load-script';

export function init() {
    import("../libs/pannellum.min.js").then(() => {
        var imgPath,
            imgTargetId,
            yawValue = 0,
            hfovValue = 100;

        $('.js-three-sixty').each(function( index, value ){

            imgPath = $(this).data('img-path');
            imgTargetId = $(this).attr('id');

            pannellum.viewer(imgTargetId, {
                "type": "equirectangular",
                "panorama": imgPath,
                "autoLoad": true,
                "pitch": -10,
                "yaw": yawValue, // Sets the panorama’s starting yaw position in degrees
                "hfov": hfovValue // set zoom
            });
        });
    }).catch(console.error);

}